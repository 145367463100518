import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import { setCurrentSearchText } from '../../../actions/NetworkActions';

import s from './PasteForm.css';
import { setTextAreaRows } from '../../../core/doc_util';

function getPasteList(geneList) {
  return geneList.map(e => e.query).join(' ');
}

class PasteForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = { geneList: [] };

  constructor(props) {
    super(props);

    this.textAreaId = 'GenePasteBox';
    this.timeout = null; // for delaying query
  }

  componentDidMount() {
    setTextAreaRows(this.textAreaId);
  }

  componentDidUpdate() {
    setTextAreaRows(this.textAreaId);
  }

  handleChange = e => {
    const { onChange, dispatch } = this.props;
    const text = e.target.value;
    dispatch(setCurrentSearchText(text));

    // Delay controller
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    // Actions that should be delayed until user stops typing
    this.timeout = setTimeout(() => {
      const strippedText = text.replace(/\s+/g, ' ');
      onChange(strippedText);
    }, 1000);
  };

  render() {
    return (
      <div>
        <textarea
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
          className={`form-control ${s.textarea}`}
          id={this.textAreaId}
          onChange={this.handleChange}
          placeholder="Paste a list of genes here.."
          value={this.props.text}
        />
      </div>
    );
  }
}

function select(state) {
  const { currentSearchGenes, currentSearchText } = state.currentSearch;
  let geneText = currentSearchText;
  if (currentSearchGenes.length && currentSearchText === null)
    geneText = getPasteList(currentSearchGenes);
  else if (currentSearchText === null) geneText = '';

  return {
    text: geneText,
  };
}

export default connect(select)(withStyles(s)(PasteForm));
