import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  ASDBROWSER_PAGE,
  COMMUNITY_MIN_THRESHOLD,
  DEEPSEA_PAGE,
  EXAMPLE_GENES,
  EXAMPLE_SEARCH,
  EXPECTO_PAGE,
  EXPECTOSC_PAGE,
  MAX_GENE_QUERY_SIZE,
  MODULE_EXAMPLE_SEARCH,
  MODULE_PAGE,
  NETWAS_PAGE,
  NETWORK_MAX_THRESHOLD,
  NETWORK_PAGE,
  SEARCH_HUMAN_MULTI_URI,
  SEARCH_HUMAN_SINGLE_URI,
  SEEK_PAGE,
  SEI_PAGE,
} from '../../../settings';
import history, { geneHistoryPush } from '../../../history';
import {
  clearCurrentSearch,
  setSearchTarget,
} from '../../../actions/NetworkActions';
import SearchBoxTable from '../SearchBoxTable';
import ConfirmClear from '../ConfirmClear/ConfirmClear';
import PasteBoxTable from '../PasteBoxTable';

const LordOfTheSearch = ({
  handleSubmit,
  onSubmit,
  initGenes,
  singleSearchTarget,
}) => {
  const dispatch = useDispatch();
  const currentSearchGenes = useSelector(
    state => state.currentSearch.currentSearchGenes,
  );
  const searchTarget = useSelector(state => state.currentSearch.searchTarget);
  const [tmpTarget, setTmpTarget] = useState(NETWORK_PAGE.pageName);
  const [showModal, setShowModal] = useState(false);
  const [bulkSearch, setBulkSearch] = useState(false);
  const [theRightSearch, setTheRightSearch] = useState(null);

  const handleBulkSearch = () => {
    setBulkSearch(true);
  };

  const handleSubmitWrapper = useCallback(
    data => {
      const { bodyTag, genes } = data;
      if (onSubmit) onSubmit({ genes }, searchTarget);
      if (handleSubmit) handleSubmit(data, searchTarget);
      else if (searchTarget === NETWORK_PAGE.pageName) {
        geneHistoryPush(data, NETWORK_PAGE.pageRoot);
      } else if (searchTarget === MODULE_PAGE.pageName) {
        history.push(`/module/overview/?body_tag=${bodyTag}`);
      }
    },
    [handleSubmit, onSubmit, searchTarget],
  );

  const handleModuleSingleTargetSubmit = useCallback(
    data => {
      const { bodyTag, genes } = data;
      if (onSubmit) onSubmit({ genes }, searchTarget);
      history.push(`/module/overview/?body_tag=${bodyTag}`);
    },
    [onSubmit, searchTarget],
  );

  const handleCancel = useCallback(() => {
    dispatch(setSearchTarget(searchTarget));
    setShowModal(false);
  }, [dispatch, searchTarget]);

  const proceedWithSelect = useCallback(
    target => {
      dispatch(setSearchTarget(target || tmpTarget));
      dispatch(clearCurrentSearch());
      setBulkSearch(false);
    },
    [dispatch, tmpTarget],
  );

  const handleSearchSelect = useCallback(
    e => {
      setTmpTarget(e.value);
      if (currentSearchGenes.length) setShowModal(true);
      else {
        proceedWithSelect(e.value);
      }
    },
    [currentSearchGenes, proceedWithSelect],
  );

  const handleContinue = useCallback(() => {
    setShowModal(false);
    proceedWithSelect();
  }, [proceedWithSelect]);

  useEffect(() => {
    if (
      currentSearchGenes.length === 0 &&
      currentSearchGenes.length > 0 &&
      bulkSearch
    )
      setBulkSearch(false);
  }, [currentSearchGenes, bulkSearch]);

  const isModuleQuery = currentSearchGenes.length >= NETWORK_MAX_THRESHOLD;
  const handleTargetSelect = singleSearchTarget ? null : handleSearchSelect;

  useEffect(() => {
    const goToPage = page => {
      history.push(page);
      dispatch(setSearchTarget(NETWORK_PAGE.pageName)); // otherwise won't be able to use 'back'
    };

    const defaultSearch = (
      <SearchBoxTable
        key={NETWORK_PAGE.pageName}
        allowBulkSearch
        onSubmit={handleSubmitWrapper}
        onBulkSearch={handleBulkSearch}
        handleTargetSelect={handleTargetSelect}
        initGenes={initGenes}
        searchUrl={SEARCH_HUMAN_SINGLE_URI}
        exampleGenes={EXAMPLE_GENES}
        exampleSearch={
          singleSearchTarget
            ? EXAMPLE_SEARCH
            : EXAMPLE_SEARCH.concat(MODULE_EXAMPLE_SEARCH)
        }
      />
    );

    const multiSearch = (
      <PasteBoxTable
        key="default-multi"
        handleTargetSelect={handleTargetSelect}
        onSubmit={geneHistoryPush}
        searchUrl={SEARCH_HUMAN_MULTI_URI}
        maxGenes={NETWORK_MAX_THRESHOLD}
      />
    );

    const moduleSearch = (
      <PasteBoxTable
        key={MODULE_PAGE.pageName}
        handleTargetSelect={handleTargetSelect}
        onSubmit={
          singleSearchTarget
            ? handleModuleSingleTargetSubmit
            : handleSubmitWrapper
        }
        searchUrl={SEARCH_HUMAN_MULTI_URI}
        showNetworks={isModuleQuery}
        exampleSearch={MODULE_EXAMPLE_SEARCH}
        minGenes={COMMUNITY_MIN_THRESHOLD}
        maxGenes={MAX_GENE_QUERY_SIZE}
      />
    );

    if (searchTarget === NETWORK_PAGE.pageName && isModuleQuery) {
      dispatch(setSearchTarget(MODULE_PAGE.pageName));
    } else if (searchTarget === NETWORK_PAGE.pageName) {
      if (bulkSearch) setTheRightSearch(multiSearch);
      else setTheRightSearch(defaultSearch); // autocomplete search
    } else if (searchTarget === MODULE_PAGE.pageName) {
      setTheRightSearch(moduleSearch);
    } else if (searchTarget === EXPECTOSC_PAGE.pageName) {
      goToPage(EXPECTOSC_PAGE.pageRoot);
    } else if (searchTarget === EXPECTO_PAGE.pageName) {
      goToPage(EXPECTO_PAGE.pageRoot);
    } else if (searchTarget === NETWAS_PAGE.pageName) {
      goToPage(NETWAS_PAGE.pageRoot);
    } else if (searchTarget === ASDBROWSER_PAGE.pageName) {
      goToPage(ASDBROWSER_PAGE.pageRoot);
    } else if (searchTarget === SEI_PAGE.pageName) {
      goToPage(SEI_PAGE.pageRoot);
    } else if (searchTarget === SEEK_PAGE.pageName) {
      goToPage(SEEK_PAGE.pageRoot);
    } else if (searchTarget && searchTarget.includes(DEEPSEA_PAGE.pageName)) {
      const analysis = searchTarget.split('-')[1];
      const analysisParam = analysis ? `?analysis=${analysis}` : '';
      goToPage(`${DEEPSEA_PAGE.pageRoot}/${analysisParam}`);
    }
  }, [
    bulkSearch,
    searchTarget,
    dispatch,
    isModuleQuery,
    handleSubmitWrapper,
    handleTargetSelect,
    initGenes,
    singleSearchTarget,
    handleModuleSingleTargetSubmit,
  ]);

  return (
    <div>
      <ConfirmClear
        showModal={showModal}
        onCancel={handleCancel}
        onContinue={handleContinue}
      />
      {theRightSearch}
    </div>
  );
};

LordOfTheSearch.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initGenes: PropTypes.arrayOf(PropTypes.object),
  singleSearchTarget: PropTypes.bool,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
};

LordOfTheSearch.defaultProps = {
  initGenes: [],
  singleSearchTarget: false,
  onSubmit: null,
  handleSubmit: null,
};

export default LordOfTheSearch;
